import React from "react";

const Footer = (props) => (
  <footer id="footer">
    <section>
      <h2>Locations</h2>
      <div className="locations">
        <div>
          <div>
            <strong>🇨🇦 Canada</strong>
          </div>
          <div>
            6080-3rd Line Road
            <br />
            Bainsville, ON
            <br />
            K0C 1E0
          </div>
        </div>
        <div>
          <div>
            <strong>🇬🇧 UK</strong>
          </div>
          <div>
            Unit 11 Wavell Dr
            <br />
            Lincoln, Lincolnshire
            <br />
            LN3 4PL
          </div>
        </div>
        <div>
          <div>
            <strong>🇺🇸 USA</strong>
          </div>
          <div>
            11320 State Route 9<br />
            Champlain, NY
            <br />
            12919
          </div>
        </div>
      </div>
    </section>
    <section>
      <h2>Contact</h2>
      <dl className="alt">
        <dt>Phone</dt>
        <dd>
          <a href="tel:+1800-681-3780">1 (800) 681-3780</a>
        </dd>
        <dt>Email</dt>
        <dd>
          <a href="mailto:info@merlintool.com?subject=Hello from merlintool.com">
            &#105;&#110;&#102;&#111;&#064;&#109;&#101;&#114;&#108;&#105;&#110;&#116;&#111;&#111;&#108;&#046;&#099;&#111;&#109;
          </a>
        </dd>
      </dl>
    </section>
    <p className="copyright">
      &copy; {new Date().getFullYear()} Merlin Tools. All rights reserved.
    </p>
  </footer>
);

export default Footer;
